<template>
<div>
    <div class="col-md-12">
        <div class="row" style="margin-top:20px">
            <div class="col-md-6">
                <h3 class="page-header">
                <i class="fa fa fa-briefcase animated bounceInDown show-info"></i>
                    EMI MANAGER
                </h3>
            </div>
            <div class="col-md-6 ">
                <div class="row" style="padding-top:18px;justify-content:flex-end;">
                    <div class="col-md-2" style="margin-right:25px">   
                        <button @click="back()" class="btn btn-primary" ><i class="fa fa-backward"></i>  Back</button>
                    </div> 
                    <div class="col-md-2" style="margin-right:25px;">             
                        <button @click="refresh()" class="btn btn-primary" ><i class="fa fa-refresh"></i>  Refresh</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <div class="panel panel-cascade">
            <div class="panel-body ">
                <form action="" method="post"> 
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-horizontal cascde-forms">
                                <div class="form-group row CHK">
                                    <label class="col-lg-4 col-md-3 control-label">Customer Name</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" id="NAME" name="NAME" type="text" value="">
                                    </div>
                                </div>
                                <div class="form-group row CHK">
                                    <label class="col-lg-4 col-md-3 control-label">Customer Mobile</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" id="MOBILE" name="MOBILE" type="text" value="">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-md-3 control-label">EMI Date : FROM</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" id="DDATEFROM" name="DDATEFROM" type="text" value="">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-md-3 control-label">DUE Date : FROM</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" id="DUEDATEFROM" name="DUEDATEFROM" type="text" value="">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 CHK">
                            <div class="form-horizontal cascde-forms">
                                <div class="form-group row">
                                    <label class="col-lg-4 col-md-3 control-label">Customer LAN</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" id="LAN" name="LAN" type="text" value="">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-md-3 control-label">Zone<span style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                                    <div class="col-lg-8 col-md-9">
                                        <select class="form-control form-cascade-control input-small .dropdown-menu" id="ZONE" name="ZONE"><option value="SELECT">SELECT</option>

                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-md-3 control-label">EMI Date : TO</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" id="DDATETO" name="DDATETO" type="text" value="">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-md-3 control-label">DUE Date : TO</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" id="DUEDATETO" name="DUEDATETO" type="text" value="">
                                    </div>
                                </div>
                                <div class="form-group row">

                                    <div class="col-lg-12 col-md-9">
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="info-box bg-warning text-white">
                        <div class="info-icon bg-primary-dark" style="padding-bottom:5px;"> 
                            <h4>
                                <i class="fa fa-check-square-o"></i>
                                Select REC BY
                            </h4>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-3" v-for="(item) in activeemployees" v-bind:key="item.id">
                                <user-operation-checkbox :operationid="item.id"></user-operation-checkbox>  {{item.name}}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group">
                            <div class="col-md-9" style="">
                                <input type="submit" id="btnsearch" value="Search" class="btn bg-primary text-white btn-md">
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="col-md-12 table-responsive">
                <div class="panel">
                    <div class="panel-body" style="overflow-x:auto;padding-left:0px">
                        <table class="table table-bordered table-hover table-striped display" id="example">
                            <thead class="table-font">
                                <tr>
                                    <th>#</th>
                                    <th>LAN</th>
                                    <th>NAME</th>
                                    <th>MOBILE</th>
                                    <th>ADRESS</th>
                                    <th>AMT</th>
                                    <th>EMI DATE</th>
                                    <th>DUE DATE</th>
                                    <th>ZONE</th>
                                    <th>MESSAGE</th>
                                    <th>FALLOW BY</th>
                                    <th>ACTION</th>
                                </tr>
                            </thead>
                            <tbody class="table-font">
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                                <img src="/Content/Admin/images/hourglass.gif" style="height:30px;width:30px;display:none;" class="LOADER">
                                <img src="/Content/Admin/images/hourglass.gif" style="height:30px;width:30px;display:none;" class="LOADER">
                                <img src="/Content/Admin/images/hourglass.gif" style="height:30px;width:30px;display:none;" class="LOADER">
                                <img src="/Content/Admin/images/hourglass.gif" style="height:30px;width:30px;display:none;" class="LOADER">
                                <div id="SHOWLAST"></div>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import Constants from '../../components/utilities/Constants.vue'
import UserOperationCheckbox from '../../components/access/UserOperationCheckbox.vue'
export default {
    mixins:[Constants],
    components:{UserOperationCheckbox},
    computed:{
		...mapGetters([
		    'activeemployees'
		]),
	},
    mounted(){
		this.$store.dispatch('fetchactiveemployees')
	},
    methods:{
    }
}
</script>